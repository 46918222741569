<template>
  <div class="editor">
    <!-- <editor-floating-menu
      :editor="editor"
      v-slot="{ commands, isActive, menu }"
    >
      <div
        :class="{ 'is-active': menu.isActive }"
        :style="`top: ${menu.top}px`"
      >
        <button
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          Bold
        </button>

      </div>
    </editor-floating-menu> -->

    <editor-menu-bar
      :editor="editor"
      v-slot="{ commands, getMarkAttrs, isActive }"
    >
      <div class="menu primary v-btn">

        <v-btn
          :class='{"active":getMarkAttrs("fontSize").fontSize === "26px"}'
          @click='commands.fontSize({fontSize:"26px"})'
        >26px</v-btn>

        <v-btn @click='commands.fontFamily({ fontFamily: "courier, monospace" })'>

          Courier
        </v-btn>

        <v-btn
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
          depressed
          small
        >
          <v-icon>{{$icon('i.FormatBold')}}</v-icon>
        </v-btn>
        <v-btn
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
          depressed
          small
          tile
        >
          <v-icon>{{$icon('i.FormatItalic')}}</v-icon>
        </v-btn>
        <v-btn
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
          depressed
          small
        >
          <v-icon>{{$icon('i.FormatUnderline')}}</v-icon>

        </v-btn>
        <v-btn
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
          depressed
          small
        >
          <v-icon>{{$icon('i.FormatStrikethroughVariant')}}</v-icon>

        </v-btn>
        <v-btn
          @click="commands.textAlign({ align: 'left' })"
          depressed
          small
        >
          <v-icon>{{$icon('i.TextAlignLeft')}}</v-icon>
        </v-btn>
        <v-btn
          @click="commands.textAlign({ align: 'center' })"
          depressed
          small
        >
          <v-icon>{{$icon('i.TextAlignCenter')}}</v-icon>
        </v-btn>
        <v-btn
          @click="commands.textAlign({ align: 'right' })"
          depressed
          small
        >
          <v-icon>{{$icon('i.TextAlignRight')}}</v-icon>
        </v-btn>
        <v-btn
          @click="commands.textAlign({ align: 'justify' })"
          depressed
          small
        >
          <v-icon>{{$icon('i.TextAlignJustify')}}</v-icon>
        </v-btn>

        <v-menu
          :close-on-content-click="true"
          :nudge-width="250"
          left
          :offset-y="true"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              type="button"
              v-on="on"
            >
              <v-icon
                size="21"
                color="#000"
                class="deforme"
              >mdi-palette</v-icon>
            </v-btn>
          </template>

          <div class="icon_pack text-center">
            <span
              v-for="(c, i) in colors"
              :key="i"
              class="pointer"
              :class="{ 'is-active1': isActive.textColor({ color: c.color }) }"
              @click.prevent="commands.textColor({ color: c.color })"
            >
              <v-icon :color="c.color">mdi-circle</v-icon>

            </span>

          </div>
        </v-menu>

        <v-menu
          :close-on-content-click="true"
          :nudge-width="250"
          left
          :offset-y="true"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              type="button"
              v-on="on"
            >
              <v-icon
                size="21"
                color="#000"
                class="deforme"
              >mdi-palette</v-icon>
            </v-btn>
          </template>

          <div class="icon_pack text-center">
            <span
              v-for="(c, i) in colors"
              :key="i"
              class="pointer"
              @click.prevent="commands.textBackgroundColor({ textBackgroundColor: c.color })"
            >
              <v-icon :color="c.color">mdi-circle</v-icon>

            </span>

          </div>
        </v-menu>

        <div
          ref="wrapper"
          class="wrapper"
        >
          <v-autocomplete
            :placeholder="$t('t.Token')"
            clearable
            item-text="displayName"
            return-object
            dense
            :items="tokens"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            v-model="tokenName"
            ref="tokenList"
            @change="addToken(commands, $event)"
            validate-on-blur
            :attach="$refs.wrapper"
          />
        </div>
      </div>
    </editor-menu-bar>
    <editor-content
      ref="editor"
      :editor="editor"
    />
  </div>
</template>

<script>

import { NodeSelection } from 'prosemirror-state'
import { Editor, EditorContent, EditorMenuBar, Node } from 'tiptap'
import {
  Blockquote,
  Bold,
  Italic,
  Strike,
  Underline,
  Heading,
  HardBreak,
  History,
  Placeholder,
  Table,
  TableHeader,
  TableCell,
  TableRow
} from 'tiptap-extensions'

import TextAlign from '@/components/tiptap-extensions/text-align'
import TextColor from '@/components/tiptap-extensions/text-color'
import TextBackgroundColor from '@/components/tiptap-extensions/text-background-color'
import FontSize from '@/components/tiptap-extensions/font-size'
import FontFamily from '@/components/tiptap-extensions/font-family'

export default {
  components: {
    EditorContent,
    EditorMenuBar
    // EditorFloatingMenu
  },
  data () {
    return {
      tokenName: '',
      content: '',
      editor: new Editor({
        preserveStyles: true,
        onUpdate: ({ getHTML, getJSON }) => this.$emit('input', this.cleanedContent(getHTML(), getJSON())),
        //   content: this.content,
        editorProps: {
          handleKeyDown: (v, e) => {
            this.$emit('keydown', e)
          }
        },
        extensions: [
          /* custom */
          new FontSize(),
          new FontFamily(),
          new TextColor(),
          new TextBackgroundColor(),
          new TextAlign(),
          /* custom */

          new Heading({ levels: [1, 2, 3] }),
          new Blockquote(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new HardBreak(),
          new History(),
          new Table(),
          new TableHeader(),
          new TableCell(),
          new TableRow(),

          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          }),
          new class extends Node {
            get name () {
              return 'token'
            }

            get view () {
              return {
                props: ['node', 'editor', 'view', 'selected'],
                methods: {
                  selectNode () {
                    const { state } = this.view
                    let tr = state.tr
                    const selection = NodeSelection.create(state.doc, this.$el.pmViewDesc.spec.getPos())
                    tr = tr.setSelection(selection)
                    this.view.dispatch(tr)
                  }
                },
                computed: {
                  displayedName () {
                    return this.node.attrs.displayName || this.$parent.$parent.tokens.filter(t => t.name === this.node.attrs.tokenName)[0]?.displayName
                  }
                },
                template: '<span :data-token="node.attrs.tokenName" class="token" :class="selected ? \'accent\' : \'primary\'" @click="selectNode">{{displayedName}}</span>'
              }
            }

            get schema () {
              return {
                attrs: {
                  tokenName: { default: '' },
                  displayName: { default: '' }
                },
                group: 'inline',
                draggable: true,
                selectable: true,
                inline: true,
                toDOM: node => ['span', { 'data-token': node.attrs.tokenName, class: 'token primary' }, node.attrs.displayName],
                parseDOM: [{
                  tag: 'span[data-token]',
                  getAttrs: dom => ({
                    tokenName: dom.getAttribute('data-token'),
                    displayName: dom.innerHTML
                  })
                }]
              }
            }

            commands ({ type }) {
              return {
                addToken:
                  ({ tokenName, displayName }) =>
                    (state, dispatch) =>
                      dispatch(state.tr.replaceSelectionWith(type.create({ tokenName, displayName })))
              }
            }
          }()
        ]
      }),
      colors: [
        { name: 'color1', color: 'yellow' },
        { name: 'primary', color: '#007bff' },
        { name: 'success', color: '#28a745' },
        { name: 'warning', color: '#ffc107' },
        { name: 'danger', color: '#dc3545' },
        { name: 'info', color: '#17a2b8' },
        { name: 'dark', color: '#343a40' }
      ]
    }
  },
  methods: {
    cleanedContent (html, json) {
      html = /^<p>\s*<\/p>$/m.test(html) ? html = '' : html
      //      this.content = { html, json }
      this.content = html

      return this.content
    },
    focus () {
      this.editor.focus()
    },
    addToken (commands, token) {
      commands.addToken({ tokenName: token.name, displayName: token.displayName })
      this.$nextTick(() => {
        this.tokenName = ''
        setTimeout(() => {
          const el = this.$refs.tokenList.$el
          const i = this.$refs.tokenList.$el.querySelector('i')
          el.className = el.className.split(' ').filter(c => !(['v-input--is-focused', 'primary--text'].includes(c))).join(' ')
          i.className = i.className.split(' ').filter(c => c !== 'primary--text').join(' ')
        })
      })
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  props: {
    placeholder: String,
    value: [Object, String],
    tokens: Array
  },
  watch: {
    value: {
      immediate: true,
      handler (n, o) {
        if (!this.lodash.isEqual(this.content, n)) {
          this.editor.setContent(n)
        }
      }
    }
    // value (v) {
    //   if (!this.lodash.isEqual(this.content, v)) {
    //     this.editor.setContent(v.html)
    //   }
    // }
  }
}
</script>

<style lang="stylus" scoped >
@css {
  >>> .menu * {
    color: hsl(0, 0%, var(--primary-lightness)) !important;
    border-color: hsl(0, 0%, var(--primary-lightness)) !important;
  }
  >>> .menu *::before,>>> .menu *::placeholder {
    color: hsla(0, 0%, var(--primary-lightness), 0.5) !important;
    border-color: hsla(0, 0%, var(--primary-lightness), 0.5) !important;
  }
}

.editor >>> p
  margin 0

.editor >>> p.is-editor-empty:first-child::before
  content attr(data-empty-text)
  float left
  color #aaa
  pointer-events none
  height 0
  font-style italic

.editor >>> .token
  padding 2px
  border-radius 4px
  cursor grab

.wrapper
  position relative

.editor
  blockquote
    border-left 3px solid rgba(red, 0.1)
    color rgba(red, 0.8)
    padding-left 0.8rem
    font-style italic

    p
      margin 0
</style>
