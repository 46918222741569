/* Working MARK example BASED ON: https://github.com/scrumpy/tiptap/issues/157 AND https://github.com/scrumpy/tiptap/issues/180 */
import { Mark } from 'tiptap'
import { updateMark, markInputRule } from 'tiptap-commands'

export default class TextAlign extends Mark {
  get name () {
    return 'textAlign'
  }

  get schema () {
    return {
      attrs: {
        align: {
          default: 'left'
        }
      },
      parseDOM: [
        {
          style: 'text-align',
          getAttrs: value => ({ align: value })
        }
      ],
      toDOM: mark => ['span', { style: `text-align: ${mark.attrs.align}; display: inline-block;` }, 0]
    }
  }

  commands ({ type }) {
    return attrs => updateMark(type, attrs)
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }
}
