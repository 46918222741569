import { Mark } from 'tiptap'
import { markInputRule, updateMark } from 'tiptap-commands'

export default class FontSize extends Mark {
  get name () {
    return 'fontSize'
  }

  get schema () {
    return {
      attrs: { fontSize: { default: '1em' } },
      parseDOM: [{
        style: 'font-size',
        getAttrs: value => {
          return { fontSize: value }
        }
      }],
      toDOM: mark => ['span', { style: `font-size: ${mark.attrs.fontSize}` }, 0]
    }
  }

  commands ({ type }) {
    return attrs => updateMark(type, attrs)
  }

  inputRules ({ type }) {
    return [markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)]
  }
}
