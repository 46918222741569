/* Working MARK example BASED ON: https://github.com/scrumpy/tiptap/issues/157 AND https://github.com/scrumpy/tiptap/issues/180 */
import { Mark } from 'tiptap'
import { updateMark } from 'tiptap-commands'

export default class TextColor extends Mark {
  get name () {
    return 'textColor'
  }

  get schema () {
    return {
      attrs: { color: { default: 'black' } },
      parseDOM: [{
        style: 'color',
        getAttrs: value => {
          return { color: value }
        }
      }],
      toDOM: mark => ['span', { style: `color: ${mark.attrs.color}; display: inline-block;` }, 0]
    }
  }

  commands ({ type }) {
    return attrs => updateMark(type, attrs)
  }
}
