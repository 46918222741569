/* Working MARK example BASED ON: https://github.com/scrumpy/tiptap/issues/157 AND https://github.com/scrumpy/tiptap/issues/180 */
import { Mark } from 'tiptap'
import { updateMark, markInputRule } from 'tiptap-commands'

export default class TextBackgroundColor extends Mark {
  get name () {
    return 'textBackgroundColor'
  }

  get schema () {
    return {
      attrs: {
        textBackgroundColor: {
          default: '#000000'
        }
      },
      parseDOM: [{
        style: 'background-color',
        getAttrs: value => {
          return { textBackgroundColor: value }
        }
      }],
      toDOM: mark => ['span', { style: `background-color: ${mark.attrs.textBackgroundColor}; display: inline-block;` }, 0]
    }
  }

  commands ({ type }) {
    return attrs => updateMark(type, attrs)
  }

  inputRules ({ type }) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)
    ]
  }
}
